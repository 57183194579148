import { Tab, Table, TableBody, TableCell, TableRow, Tabs } from "@mui/material";
import {
	SaveDraftDraftTableCell,
	SaveDraftExteriorTableHeader,
	SaveDraftTableContainer,
	SaveDraftTableDisclaimer,
	SaveDraftTableWrapper
} from "./SaveDraftTable.styles";
import {
	NewOrderCancelButton,
	NewOrderOpen,
	NewOrderRemoveButton,
	RemoveDraftConfirm,
	RemoveDraftModalSubtitle,
	RemoveDraftModalTitle,
	RemoveTemplateConfirm,
	RemoveTemplateModalSubtitle,
	RemoveTemplateModalTitle,
	SaveDraftDraftTab,
	SaveDraftTableDisclaimerText,
	SaveDraftTableSearchText,
	SaveDraftTemplateTab,
	SaveDraftTHeadAccount,
	SaveDraftTHeadDate,
	SaveDraftTHeadDesigner,
	SaveDraftTHeadDraft,
	SaveDraftTHeadProductLine,
	SaveTemplateTableSearchText
} from "../../constants";
import { Fragment, MouseEvent, SyntheticEvent, useEffect, useMemo, useRef, useState } from "react";
import {
	useDeleteDraftOrderMutation,
	useDeleteTemplateOrderMutation,
	useLazyGetDraftOrdersQuery,
	useLazyGetTemplatesQuery
} from "features/api/orderApi";
import { LinkButton } from "components/Common/Link";
import SaveDraftNoDrafts from "../SaveDraftNoDrafts";
import SaveDraft400Error from "../SaveDraft400Error";
import SaveDraftTableSkeleton from "./SaveDraftTableSkeleton";
import { format } from "date-fns";
import SortableTableHead, {
	HeadCell,
	Order
} from "components/NewOrders/SaveDraft/SaveDraftTable/components/SortableTableHead.tsx";
import SuggestedSearch from "components/Common/SuggestedSearch/SuggestedSearch";
import SaveDraftTableFilters from "./components/SaveDraftTableFilters.tsx";
import useSession from "hooks/useSession.ts";
import { CacheKeys, InitialDraftFilterOptions } from "pages/OrderPages/OrderListPage/orderListConstants.ts";
import { FilterOpts } from "pages/OrderPages/OrderListPage/OrderListPage.tsx";
import { getFilteredDrafts } from "./saveDraftUtils.ts";
import { UnavailableDataPlaceholderText } from "constants/text.ts";
import { useNavigate } from "react-router-dom";
import { models } from "types/api/viewModels.ts";
import LeaveFlowDialog from "../../../Common/LeaveFlowDialog/LeaveFlowDialog.tsx";
import { displayDraftDesigner } from "utils/order.ts";

function a11yProps(index: number) {
	return {
		id: `new-order-save-draft-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`
	};
}

const headCells = [
	{
		id: "updatedAt",
		numeric: false,
		disablePadding: false,
		label: SaveDraftTHeadDate,
		sortable: true
	},
	{
		id: "draft",
		numeric: false,
		disablePadding: false,
		label: SaveDraftTHeadDraft,
		sortable: false
	},
	{
		id: "account",
		numeric: false,
		disablePadding: false,
		label: SaveDraftTHeadAccount,
		sortable: false
	},
	{
		id: "designer",
		numeric: false,
		disablePadding: false,
		label: SaveDraftTHeadDesigner,
		sortable: false
	},
	{
		id: "productLine",
		numeric: false,
		disablePadding: false,
		label: SaveDraftTHeadProductLine,
		sortable: false
	},
	{
		id: "empty",
		numeric: false,
		disablePadding: false,
		label: "",
		sortable: false
	}
] as const satisfies HeadCell[];

type CellIds = (typeof headCells)[number]["id"];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order: Order, orderBy: CellIds): (a: any, b: any) => number {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

const SaveDraftTable = () => {
	const [filterOptions, setFilterOptions] = useSession<FilterOpts>(CacheKeys.DraftFilters, InitialDraftFilterOptions);
	const [order, setOrder] = useState<Order>("desc");
	const [orderBy, setOrderBy] = useState<CellIds>("updatedAt");
	const [value, setValue] = useState<number>(0);
	const [searchValue, setSearchValue] = useState<string | null>(null);
	const navigate = useNavigate();
	const [removeDraft, setRemoveDraft] = useState(false);
	const draftToRemove = useRef("");
	const [trigger, { data: drafts, error, isFetching }] = useLazyGetDraftOrdersQuery();
	const [templateTrigger, { data: templates, error: templateError, isFetching: isTemplateFetching }] =
		useLazyGetTemplatesQuery();
	const [deleteTemplate, { isLoading: deleteTemplateLoading }] = useDeleteTemplateOrderMutation();
	const [deleteDraft, { isLoading: deleteLoading }] = useDeleteDraftOrderMutation();
	const [sortedDrafts, setSortedDrafts] = useState(drafts?.slice().sort(getComparator(order, orderBy)));
	const [isTemplateView, setIsTemplateView] = useState(false);
	const handleChange = (event: SyntheticEvent, newValue: number) => {
		setValue(newValue);
		setIsTemplateView(newValue === 1);
		if (newValue === 0) {
			setSortedDrafts(drafts?.slice().sort(getComparator(order, orderBy)));
		} else {
			setSortedDrafts(templates?.slice().sort(getComparator(order, orderBy)));
		}
	};

	// Below useEffect handles switching back and forth between Templates and Drafts tabs
	useEffect(() => {
		if (isTemplateView) {
			templateTrigger({});
			setSortedDrafts(templates?.slice().sort(getComparator(order, orderBy)));
		} else {
			trigger({});
			setSortedDrafts(drafts?.slice().sort(getComparator(order, orderBy)));
		}
	}, [trigger, templateTrigger, drafts, order, orderBy, templates, isTemplateView]);

	const handleRequestSort = (_event: MouseEvent<unknown>, property: CellIds) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleOpenDraft = (draftId: string) => {
		if (draftId) {
			navigate(`/new-order/build-order/${draftId}`);
		}
	};

	const handleRemoveDraftModal = (draftId: string) => {
		if (draftId) {
			setRemoveDraft(true);
			draftToRemove.current = draftId;
		}
	};

	const closeRemoveDraftModal = () => {
		setRemoveDraft(false);
	};

	const handleRemoveDraft = () => {
		if (isTemplateView) {
			deleteTemplate(draftToRemove.current).then(() => {
				templateTrigger({});
			});
		} else {
			deleteDraft(draftToRemove.current).then(() => {
				trigger({});
			});
		}
		setRemoveDraft(false);
	};

	const searchOptions = useMemo(() => {
		if (isTemplateView) {
			return (
				templates
					?.map((draft?: { draftName?: string | null }) => draft?.draftName)
					.filter((option) => option !== undefined && option !== null) ?? []
			);
		} else {
			return (
				drafts
					?.map((draft?: { draftName?: string | null }) => draft?.draftName)
					.filter((option) => option !== undefined && option !== null) ?? []
			);
		}
	}, [drafts, templates, isTemplateView]);

	const handleDraftSearch = (_event: SyntheticEvent, values: string | null) => {
		setSearchValue(values ?? null);
	};

	const filteredDrafts = useMemo(
		() => getFilteredDrafts(filterOptions, sortedDrafts!),
		[filterOptions, sortedDrafts]
	);

	const getDraftTableRow = (draft: models["DraftOrderViewModel"]) => {
		const config = draft?.configurations?.reduce((accum, current, index) => {
			if (!accum) return;
			if (index !== 0) {
				return {
					...accum,
					accountId: [accum.accountId, `${current.accountNumber} - ${current.accountName}`].join(","),
					productLineDescription:
						accum?.globals?.productLine?.description !== ""
							? accum?.globals?.productLine?.description
							: UnavailableDataPlaceholderText
				};
			} else
				return {
					...accum,
					accountId: `${accum.accountNumber} - ${accum.accountName}`,
					productLineDescription:
						accum?.globals?.productLine?.description !== ""
							? accum?.globals?.productLine?.description
							: UnavailableDataPlaceholderText
				};
		}, draft?.configurations[0]);

		return (
			<Fragment>
				<TableCell
					data-testid="new-order-save-draft-table-date-content"
					component="th"
					scope="row"
				>
					{format(new Date(String(draft.updatedAt)), "MM/dd/yy")}
				</TableCell>
				<SaveDraftDraftTableCell data-testid="new-order-save-draft-table-draft-name-content">
					{draft.draftName}
				</SaveDraftDraftTableCell>
				<TableCell data-testid="new-order-save-draft-table-account-content">
					{config?.accountId?.split(",").map((accountId, index) => <div key={index}>{accountId}</div>)}
				</TableCell>
				<TableCell data-testid="new-order-save-draft-table-designer-name-content">
					{displayDraftDesigner(draft?.designerName, draft?.designerNumber)}
				</TableCell>
				<TableCell data-testid="new-order-save-draft-table-brand-content">
					{config?.globals?.productLine?.description}
				</TableCell>
				<TableCell>
					<LinkButton
						onClick={() => handleOpenDraft(draft.draftOrderId ?? "")}
						data-testid="new-order-save-draft-table-edit-link"
					>
						{NewOrderOpen}
					</LinkButton>

					<LinkButton
						variant="remove"
						data-testid="new-order-save-draft-table-remove-link"
						onClick={() => handleRemoveDraftModal(draft.draftOrderId ?? "")}
					>
						{NewOrderRemoveButton}
					</LinkButton>
				</TableCell>
			</Fragment>
		);
	};

	const displayLoadingSkeleton = isFetching || deleteLoading || deleteTemplateLoading || isTemplateFetching;
	const displayTable =
		sortedDrafts && sortedDrafts?.length > 0 && !isFetching && !deleteLoading && !deleteTemplateLoading;

	return (
		<>
			{removeDraft && (
				<LeaveFlowDialog
					firstButtonText={NewOrderCancelButton.toUpperCase()}
					dialogState={removeDraft}
					secondButtonText={isTemplateView ? RemoveTemplateConfirm : RemoveDraftConfirm}
					subtitle={isTemplateView ? RemoveTemplateModalSubtitle : RemoveDraftModalSubtitle}
					title={isTemplateView ? RemoveTemplateModalTitle : RemoveDraftModalTitle}
					handleDialogClose={closeRemoveDraftModal}
					handleDialogExitOrder={closeRemoveDraftModal}
					handleOpenDraftModal={() => handleRemoveDraft()}
				/>
			)}

			{displayLoadingSkeleton ? (
				<SaveDraftTableSkeleton />
			) : (
				<SaveDraftTableContainer>
					<SaveDraftExteriorTableHeader>
						<div>
							<Tabs
								value={value}
								onChange={handleChange}
								aria-label="Save draft table tabs"
							>
								<Tab
									data-testid="new-order-save-draft-draft-tab"
									label={SaveDraftDraftTab}
									{...a11yProps(0)}
								/>
								<Tab
									data-testid="new-order-save-draft-template-tab"
									label={SaveDraftTemplateTab}
								/>
							</Tabs>
						</div>
						{drafts && !isTemplateView && (
							<SaveDraftTableFilters
								filterOptions={filterOptions}
								setFilterOptions={setFilterOptions}
								drafts={drafts}
							/>
						)}

						{templates && isTemplateView && (
							<SaveDraftTableFilters
								filterOptions={filterOptions}
								setFilterOptions={setFilterOptions}
								drafts={templates}
							/>
						)}

						<SuggestedSearch
							ariaLabel="search-drafts"
							dataTestId="new-order-save-draft-table-search"
							dropDownOpenOnChar={3}
							placeholder={isTemplateView ? SaveTemplateTableSearchText : SaveDraftTableSearchText}
							handleSearch={(event, values) => handleDraftSearch(event, values ?? "")}
							id="new-order-draft-table-search-drafts"
							searchOptions={searchOptions ?? []}
							searchValue={searchValue ?? ""}
							size="small"
						/>
					</SaveDraftExteriorTableHeader>

					<SaveDraftTableDisclaimer>{SaveDraftTableDisclaimerText}</SaveDraftTableDisclaimer>

					{!isTemplateView && error && "status" in error && error.status === 400 && <SaveDraft400Error />}
					{isTemplateView && templateError && "status" in templateError && templateError.status === 400 && (
						<SaveDraft400Error />
					)}

					{displayTable ? (
						<SaveDraftTableWrapper>
							<Table
								data-testid="new-order-save-draft-table"
								aria-label="Save draft table"
							>
								<SortableTableHead
									orderBy={orderBy}
									order={order}
									onRequestSort={handleRequestSort}
									headCells={headCells}
								/>
								<TableBody>
									{filteredDrafts?.map((draft) => {
										const foundSearch = draft.draftName
											?.toUpperCase()
											?.includes(searchValue?.toUpperCase() ?? "");
										if (foundSearch) {
											return (
												<TableRow key={draft.draftOrderId}>{getDraftTableRow(draft)}</TableRow>
											);
										} else {
											return <></>;
										}
									})}
								</TableBody>
							</Table>
						</SaveDraftTableWrapper>
					) : (
						<SaveDraftNoDrafts />
					)}
				</SaveDraftTableContainer>
			)}
		</>
	);
};

export default SaveDraftTable;
