import {
	EditLineItemHeaderContainer,
	EditLineItemHeaderRow,
	EditAddModificationWrapper,
	EditAddModificationGlobalAttributesWrapper,
	EditConfigurationSelect
} from "./EditLineItemStyles.ts";
import { DimensionContainer, RequiredDimensionsValues } from "../RequiredDimensions/RequiredDimensionsStyles.ts";
import { EditLineItemHeader, ChangeGlobalAttributeGroup, EditProductLineLabel } from "./constants.ts";
import Header from "../../Common/LineItemGrid/Header/Header.tsx";
import {
	AddLineCancelButton,
	AddLineDepthLabel,
	AddLineHeightLabel,
	AddLineItemQuantityPlaceholder,
	AddLineItemStandardDimensionsHeader,
	AddLineSaveButton,
	AddLineWidthLabel,
	RequiredDimensionError,
	SelectRequiredDimensions
} from "../AddLineItem/constants.ts";
import AutoComplete from "../../Common/Autocomplete/Autocomplete.tsx";
import { MutableRefObject, SyntheticEvent, useState } from "react";
import {
	AddLineItemButtonWrapper,
	AddLineItemStandardContainer,
	AddLineItemStandardDimensions
} from "../AddLineItem/AddLineItemStyles.ts";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import AddModification from "../AddModification/AddModification.tsx";
import { models } from "types/api/viewModels.ts";
import { ReplacementReasonAndProblemInputLabel } from "components/Replacements/SelectReplacementReasonAndProblemDropdowns.styles.ts";
import {
	calculateIntervals,
	ConfigurationGroup,
	getAttributes,
	getSubheaderAttributes
} from "components/NewOrders/utils/NewOrderUtils.tsx";
import styles from "pages/replacements-page-styles.module.css";
import { Controller, useForm } from "react-hook-form";
import { FormSchema, schema } from "./schema.ts";
import { yupResolver } from "@hookform/resolvers/yup";
import useAddModification from "components/NewOrders/AddModification/hooks/useAddModification.ts";

interface EditLineItemProps {
	group: ConfigurationGroup;
	configuration: models["PendingOrderConfigurationViewModel"];
	selectedEditItem: MutableRefObject<models["PendingLineItemViewModel"] | undefined>;
	description: string | null | undefined;
	editLineItemIndex: number | undefined;
	isAddLineItem?: boolean;
	addEditVisibility(index?: number): void;
	handleMoveLineItem(
		item: models["PendingLineItemViewModel"],
		deleteItemIndex: number | undefined,
		deleteConfigId: string | null | undefined,
		destinationConfigId: string | null | undefined
	): void;
}

const EditLineItem = ({
	group,
	configuration,
	addEditVisibility,
	selectedEditItem,
	description,
	editLineItemIndex,
	handleMoveLineItem,
	isAddLineItem
}: EditLineItemProps) => {
	const {
		getModificationFormErrors,
		handleSetModificationFormData,
		handleSetModificationFormErrors,
		modificationFormErrors,
		modificationFormValues,
		modifications
	} = useAddModification(selectedEditItem?.current?.id, group?.globals?.productLine?.id);
	const [requiredDimensionsValues, setRequiredDimensionsValues] = useState<models["PendingValueViewModel"][]>(
		selectedEditItem.current?.requiredDimensions ?? []
	);
	const { handleSubmit, control } = useForm({
		mode: "onChange",
		resolver: yupResolver(schema),
		reValidateMode: "onChange",
		defaultValues: {
			productSelectQuantity: selectedEditItem.current?.quantityOrdered,
			destinationConfigurationId: configuration.configurationId ?? ""
		}
	});

	const quantityValues = Array.from({ length: 99 }, (_, i) => i + 1);
	const quantity = quantityValues.join().split(",");

	const handleMouseDownCapture = (e: SyntheticEvent) => {
		e.stopPropagation();
	};

	const standardDimensions =
		selectedEditItem.current?.standardDepth ||
		selectedEditItem.current?.standardHeight ||
		selectedEditItem.current?.standardWidth;

	const generateMenuItems = () => {
		return group.configurations
			.map((configuration) => (
				<MenuItem
					key={configuration.configurationId}
					value={configuration.configurationId ?? ""}
				>
					<EditConfigurationSelect>
						<span>{getAttributes(configuration)}</span>
						<span>{getSubheaderAttributes(configuration)}</span>
					</EditConfigurationSelect>
				</MenuItem>
			))
			.filter((item) => typeof item !== "undefined");
	};

	const handleSaveAction = (formValues: FormSchema) => {
		const { errors, isValid } = getModificationFormErrors(modificationFormValues);

		handleSetModificationFormErrors(errors);

		const modifications: models["PendingModificationViewModel"][] = Array.from(modificationFormValues.values()).map(
			(mod) => ({
				id: mod?.id,
				sku: mod?.sku,
				description: mod?.description,
				lineItemNumber: "",
				itemKey: mod?.sku,
				values: mod?.values
			})
		);

		if (isValid) {
			const editedLineItem: models["PendingLineItemViewModel"] = {
				...selectedEditItem?.current,
				quantityOrdered: formValues.productSelectQuantity,
				lineItemNumber: "1",
				modifications,
				requiredDimensions: requiredDimensionsValues
			};

			handleMoveLineItem(
				editedLineItem,
				editLineItemIndex,
				configuration.configurationId,
				formValues.destinationConfigurationId
			);
			addEditVisibility();
		}
	};

	const handleDimensionChange = (
		value: number,
		description: string | null,
		increment: number,
		maxValue: number,
		minValue: number,
		index: number
	) => {
		const requiredDimensions: models["RequiredDimensionsViewModel"] & models["PendingValueViewModel"] = {
			description: description,
			value: value,
			increment: increment,
			maxValue: maxValue,
			minValue: minValue
		};

		setRequiredDimensionsValues((prev) =>
			prev.map((dimension, prevIndex) => {
				if (index === prevIndex) return requiredDimensions;
				return dimension;
			})
		);
	};

	return (
		<form
			onSubmit={(event) => {
				event.stopPropagation();
				handleSubmit(handleSaveAction)(event);
			}}
			noValidate
		>
			<EditLineItemHeaderContainer>
				<div data-testId="new-order-draft-table-line-header">{EditLineItemHeader}</div>
				<EditLineItemHeaderRow>
					<Header
						description={selectedEditItem?.current?.description ?? ""}
						key={`${selectedEditItem?.current?.sku}-${selectedEditItem?.current?.lineItemNumber}-header`}
						header={selectedEditItem.current?.sku ?? ""}
						lineNumber={selectedEditItem.current?.lineItemNumber ?? ""}
					/>

					<div>
						<span>{EditProductLineLabel}</span>
						<span data-testId="edit-line-item-description">{description}</span>
					</div>

					<Controller
						name="productSelectQuantity"
						control={control}
						render={({ field: { onChange, value = null } }) => (
							<AutoComplete
								value={value}
								required
								disableClearable
								onChange={(_event, newValue) => {
									onChange(newValue);
								}}
								onMouseDownCapture={handleMouseDownCapture}
								label={AddLineItemQuantityPlaceholder}
								dataTestId="edit-line-item-quantity"
								options={quantity}
								isError={false}
								errorText=""
							/>
						)}
					/>
				</EditLineItemHeaderRow>

				<EditAddModificationGlobalAttributesWrapper>
					<FormControl
						size="small"
						fullWidth
					>
						<ReplacementReasonAndProblemInputLabel>
							{ChangeGlobalAttributeGroup}
						</ReplacementReasonAndProblemInputLabel>
						<Controller
							name="destinationConfigurationId"
							control={control}
							render={({ field: { onChange, value = null } }) => (
								<Select
									label={ChangeGlobalAttributeGroup}
									data-testid="edit-item-select"
									onChange={onChange}
									value={value ?? ""}
									renderValue={(configurationId: string) => (
										<div className={styles.replacementProblemDropdownOption}>
											<span>
												{getAttributes(
													group?.configurations?.find(
														(config) => config?.configurationId === configurationId
													)
												)}
											</span>
										</div>
									)}
								>
									{generateMenuItems()}
								</Select>
							)}
						/>
					</FormControl>
				</EditAddModificationGlobalAttributesWrapper>

				{standardDimensions && (
					<AddLineItemStandardContainer>
						<div data-testId="edit-line-item-standard-dimensions-header">
							{AddLineItemStandardDimensionsHeader}
						</div>

						<AddLineItemStandardDimensions isAddLineItem={isAddLineItem}>
							{selectedEditItem.current?.standardWidth && (
								<div data-testId="edit-line-item-standard-dimensions-width">
									<span>{AddLineWidthLabel}</span>
									<span>{selectedEditItem.current?.standardWidth}&quot;</span>
								</div>
							)}

							{selectedEditItem.current?.standardDepth && (
								<div data-testId="edit-line-item-standard-dimensions-depth">
									<span>{AddLineDepthLabel}</span>
									<span>{selectedEditItem.current?.standardDepth}&quot;</span>
								</div>
							)}

							{selectedEditItem.current?.standardHeight && (
								<div data-testId="edit-line-item-standard-dimensions-height">
									<span>{AddLineHeightLabel}</span>
									<span>{selectedEditItem.current?.standardHeight}&quot;</span>
								</div>
							)}
						</AddLineItemStandardDimensions>
					</AddLineItemStandardContainer>
				)}

				{selectedEditItem.current?.allowModifications && (
					<EditAddModificationWrapper>
						<AddModification
							handleClearFieldError={handleSetModificationFormErrors}
							onModificationChange={handleSetModificationFormData}
							selectedEditItem={selectedEditItem}
							errors={modificationFormErrors}
							formValues={modificationFormValues}
							modifications={modifications}
						/>
					</EditAddModificationWrapper>
				)}

				{selectedEditItem.current?.requiredDimensions?.length !== 0 && (
					<>
						<h6 data-testid="edit-line-item-dimension-header">{SelectRequiredDimensions}</h6>

						<DimensionContainer>
							{selectedEditItem?.current?.requiredDimensions?.map((dimension: any, dimensionIndex) => {
								return (
									dimension && (
										<div>
											<AutoComplete
												options={calculateIntervals(
													dimension.minValue,
													dimension.maxValue,
													dimension.increment
												)}
												defaultValue={dimension.value}
												onMouseDownCapture={(e: SyntheticEvent) => {
													if (
														e.target instanceof HTMLElement &&
														e.target.nodeName === "INPUT"
													) {
														e.stopPropagation();
													}
												}}
												onChange={(_, value) =>
													handleDimensionChange(
														value,
														dimension.description,
														dimension.increment,
														dimension.maxValue,
														dimension.minValue,
														dimensionIndex
													)
												}
												isLoading={false}
												label={dimension.description ?? ""}
												dataTestId="add-line-item-dimensions"
												getOptionLabel={(option) => String(option) + '"'}
												required
												isError={false}
												errorText={RequiredDimensionError}
												disableClearable
											/>

											<RequiredDimensionsValues data-testid="add-line-item-dimension-mix-max">
												{`Min: ${dimension.minValue}", Max: ${dimension.maxValue}"`}
											</RequiredDimensionsValues>
										</div>
									)
								);
							})}
						</DimensionContainer>
					</>
				)}

				<AddLineItemButtonWrapper isAddLineItem={isAddLineItem}>
					<div>
						<Button
							data-testId="add-line-item-cancel-button"
							variant="text"
							onClick={() => addEditVisibility()}
						>
							{AddLineCancelButton}
						</Button>
					</div>

					<div>
						<Button
							data-testId="add-line-item-save-button"
							variant="contained"
							type="submit"
						>
							{AddLineSaveButton}
						</Button>
					</div>
				</AddLineItemButtonWrapper>
			</EditLineItemHeaderContainer>
		</form>
	);
};
export default EditLineItem;
