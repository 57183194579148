import {
	AddLineItemButtonWrapper,
	AddLineItemContainer,
	AddLineItemInputFormControl,
	AddLineItemStandardContainer,
	AddLineItemStandardDimensions
} from "./AddLineItemStyles.ts";
import { Button } from "@mui/material";
import { MutableRefObject, SyntheticEvent, useEffect, useMemo, useRef, useState } from "react";
import AutoComplete from "../../Common/Autocomplete/Autocomplete.tsx";
import { useGetLineItemsQuery } from "features/api/newOrderApi.ts";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema.ts";
import {
	AddLineAddAnotherItemButton,
	AddLineAddAnotherTextOne,
	AddLineCancelButton,
	AddLineDepthLabel,
	AddLineEnterText,
	AddLineHeightLabel,
	AddLineItemHeader,
	AddLineItemInvalidProductError,
	AddLineItemProductPlaceholder,
	AddLineItemQuantityPlaceholder,
	AddLineItemRequired,
	AddLineItemStandardDimensionsHeader,
	AddLineSaveButton,
	AddLineWidthLabel,
	RequiredDimensionError,
	SelectRequiredDimensions
} from "./constants.ts";
import { models } from "types/api/viewModels.ts";
import { calculateIntervals } from "components/NewOrders/utils/NewOrderUtils.tsx";
import { DimensionContainer, RequiredDimensionsValues } from "../RequiredDimensions/RequiredDimensionsStyles.ts";
import AddModification from "../AddModification/AddModification.tsx";
import { getFormFieldProps } from "utils/form.tsx";
import { stringArraySortAlphabetically } from "utils/array.ts";
import useAddModification from "components/NewOrders/AddModification/hooks/useAddModification.ts";

interface AddNewLineItemModalProps {
	addItemVisibility(groupIndex?: number, configIndex?: number): void;
	configuration: models["PendingOrderConfigurationViewModel"];
	handleLineItem: (configurationId: string, newLineItem: models["PendingLineItemViewModel"]) => void;
	selectedEditItem: MutableRefObject<models["PendingLineItemViewModel"] | undefined>;
	isAddLineItem?: boolean;
}

const AddLineItem = ({
	addItemVisibility,
	configuration,
	handleLineItem,
	selectedEditItem,
	isAddLineItem
}: AddNewLineItemModalProps) => {
	const [quantityValue, setQuantityValue] = useState(1);
	const [searchValue, setSearchValue] = useState<string | null>(null);
	const { control, handleSubmit, trigger, formState, register, getValues, reset } = useForm({
		mode: "onChange",
		resolver: yupResolver(schema),
		reValidateMode: "onChange"
	});
	const formFieldData = { formState, register, schema };

	const dimensions = useRef<Record<string, number | null>>({});
	const [submitted, setSubmitted] = useState(new Date().getTime());
	const originalSubmitted = useRef(submitted);
	const [requiredDimensionsValues, setRequiredDimensionsValues] = useState<
		(models["PendingValueViewModel"] | null)[]
	>([]);

	const param = configuration.globals?.productLine?.code ?? "";
	const { data: newLineItems, isLoading } = useGetLineItemsQuery(param, { skip: !param });

	const foundSearch = useMemo(() => {
		return newLineItems?.find((item) => `${item.sku} - ${item.description}`.includes(searchValue!));
	}, [newLineItems, searchValue]);

	const {
		getModificationFormErrors,
		handleSetModificationFormData,
		handleSetModificationFormErrors,
		modificationFormErrors,
		modificationFormValues,
		modifications
	} = useAddModification(foundSearch?.id ?? selectedEditItem?.current?.id, configuration?.globals?.productLine?.id);

	useEffect(() => {
		if (foundSearch?.requiredDimensions?.length !== 0) {
			foundSearch?.requiredDimensions.forEach((dimension) => {
				setRequiredDimensionsValues((prev) => {
					const dimensionValues = [...prev];
					dimensionValues.push(null);
					return dimensionValues;
				});
			});
		} else {
			setRequiredDimensionsValues([]);
		}
	}, [foundSearch?.requiredDimensions]);

	const handleAddAnother = () => {
		trigger().then((valid) => {
			valid && handleSaveAction(true);
		});
	};

	useEffect(() => {
		const handleQuickKeys = (event: any) => {
			if (event.key === "\\") {
				event.preventDefault();
				handleAddAnother();
			}
		};
		document.addEventListener("keydown", handleQuickKeys);

		return () => {
			document.removeEventListener("keydown", handleQuickKeys);
		};
	});

	const handleChangeProduct = (_event: SyntheticEvent, values: string | null) => {
		setSearchValue(values ?? null);
		handleSetModificationFormData(new Map());
	};

	const quantityValues = Array.from({ length: 99 }, (_, i) => i + 1);
	const quantity = quantityValues.join().split(",");

	const newLineItemMap = useMemo(() => {
		if (!isLoading) {
			return newLineItems?.map((item) => {
				return `${item.sku} - ${item.description}`;
			});
		}
	}, [isLoading, newLineItems]);

	const sortedOptions = useMemo(() => stringArraySortAlphabetically(newLineItemMap), [newLineItemMap]);

	const handleMouseDownCapture = (e: SyntheticEvent) => {
		e.stopPropagation();
	};

	const handleQuantityChange = (_event: SyntheticEvent, value: number) => {
		setQuantityValue(value);
	};

	const handleDimensionChange = (
		id: string | null,
		value: number,
		description: string | null,
		increment: number,
		maxValue: number,
		minValue: number,
		index: number
	) => {
		const requiredDimensions: models["RequiredDimensionsViewModel"] & models["PendingValueViewModel"] = {
			id: id,
			description: description,
			value: value,
			increment: increment,
			maxValue: maxValue,
			minValue: minValue
		};

		setRequiredDimensionsValues((prev) =>
			prev.map((dimension, prevIndex) => {
				if (index === prevIndex) return requiredDimensions;
				return dimension;
			})
		);
	};

	const handleSaveAction = (addAnother = false) => {
		setSubmitted(new Date().getTime());
		const { errors, isValid } = getModificationFormErrors(modificationFormValues);

		handleSetModificationFormErrors(errors);

		const modifications: models["PendingModificationViewModel"][] = Array.from(modificationFormValues.values()).map(
			(mod) => ({
				id: mod?.id,
				sku: mod?.sku,
				description: mod?.description,
				lineItemNumber: "",
				itemKey: mod?.sku,
				values: mod?.values
			})
		);

		const { productSelect } = getValues();
		const searchSku = productSelect?.split("-")[0];
		const searchDescription = productSelect?.split(" - ")[1];

		if (
			searchValue !== null &&
			requiredDimensionsValues.length === foundSearch?.requiredDimensions.length &&
			requiredDimensionsValues.every((dimension) => dimension !== null) &&
			isValid
		) {
			const newLineItem: models["PendingLineItemViewModel"] = {
				...foundSearch,
				description: searchDescription ?? null,
				lineItemNumber: "1",
				sku: searchSku ?? null,
				modifications: modifications,
				quantityOrdered: quantityValue,
				requiredDimensions: requiredDimensionsValues.filter((dimension) => dimension !== null)
			};

			handleLineItem(configuration?.configurationId ?? "", newLineItem);
			setSearchValue(null);
			setQuantityValue(1);
			reset();

			if (!addAnother) {
				addItemVisibility();
			}
		}
	};

	const containerRef = useRef<HTMLFormElement | null>(null);

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	return (
		<form
			ref={containerRef}
			onSubmit={(event) => {
				event.stopPropagation();
				handleSubmit(() => handleSaveAction())(event);
			}}
			noValidate
		>
			<AddLineItemContainer>
				<div data-testId="add-line-item-header">{AddLineItemHeader}</div>
				<div data-testId="add-line-item-required">{AddLineItemRequired}</div>
				<AddLineItemInputFormControl>
					<Controller
						name="productSelect"
						control={control}
						render={({ field: { onChange, value = null }, fieldState: { error } }) => (
							<div>
								<AutoComplete
									dataTestId="add-line-item-product-search"
									options={sortedOptions ?? []}
									value={searchValue ?? ""}
									onChange={(event, values) => {
										handleChangeProduct(event, values ?? null);
										onChange(values);
									}}
									isLoading={isLoading}
									onMouseDownCapture={handleMouseDownCapture}
									isError={Boolean(error)}
									errorText={error?.message}
									label={AddLineItemProductPlaceholder}
									virtualize
									required
									disableClearable
									autoFocus
									noOptionsText={AddLineItemInvalidProductError}
								/>
							</div>
						)}
					/>

					<AutoComplete
						{...getFormFieldProps({
							name: "productSelectQuantity",
							...formFieldData
						})}
						options={quantity}
						value={quantityValue}
						onChange={handleQuantityChange}
						onMouseDownCapture={handleMouseDownCapture}
						getOptionLabel={(option) => String(option) || ""}
						defaultValue={quantityValue}
						isError={false}
						errorText=""
						label={AddLineItemQuantityPlaceholder}
						dataTestId="add-line-item-quantity"
						required
						disableClearable
					/>
				</AddLineItemInputFormControl>

				{foundSearch && searchValue && (
					<>
						{(foundSearch.standardDepth || foundSearch.standardHeight || foundSearch.standardWidth) && (
							<AddLineItemStandardContainer>
								<div data-testId="add-line-item-standard-dimensions-header">
									{AddLineItemStandardDimensionsHeader}
								</div>
								<AddLineItemStandardDimensions isAddLineItem={isAddLineItem}>
									{foundSearch.standardDepth && (
										<div data-testId="add-line-item-standard-dimensions-depth">
											<span>{AddLineDepthLabel}</span>
											<span>{foundSearch.standardDepth}&quot;</span>
										</div>
									)}

									{foundSearch.standardHeight && (
										<div data-testId="add-line-item-standard-dimensions-height">
											<span>{AddLineHeightLabel}</span>
											<span>{foundSearch.standardHeight}&quot;</span>
										</div>
									)}

									{foundSearch.standardWidth && (
										<div data-testId="add-line-item-standard-dimensions-width">
											<span>{AddLineWidthLabel}</span>
											<span>{foundSearch.standardWidth}&quot;</span>
										</div>
									)}
								</AddLineItemStandardDimensions>
							</AddLineItemStandardContainer>
						)}

						{foundSearch?.requiredDimensions?.length !== 0 && (
							<h6 data-testid="add-line-item-dimension-header">{SelectRequiredDimensions}</h6>
						)}

						{foundSearch?.requiredDimensions && (
							<DimensionContainer>
								{foundSearch?.requiredDimensions?.map((dimension, dimensionIndex) => {
									return (
										dimension && (
											<div key={dimension.id}>
												<AutoComplete
													key={dimension.id}
													options={calculateIntervals(
														dimension.minValue,
														dimension.maxValue,
														dimension.increment
													)}
													onMouseDownCapture={(e: SyntheticEvent) => {
														if (
															e.target instanceof HTMLElement &&
															e.target.nodeName === "INPUT"
														) {
															e.stopPropagation();
														}
													}}
													onChange={(_, value) =>
														handleDimensionChange(
															dimension.id,
															value,
															dimension.description,
															dimension.increment,
															dimension.maxValue,
															dimension.minValue,
															dimensionIndex
														)
													}
													isLoading={false}
													label={
														dimension.description ? "Select " + dimension.description : ""
													}
													dataTestId="add-line-item-dimensions"
													getOptionLabel={(option) => String(option) + '"'}
													required
													isError={
														originalSubmitted.current !== submitted &&
														!requiredDimensionsValues[dimensionIndex]
													}
													errorText={RequiredDimensionError}
													disableClearable
												/>

												{(submitted || dimensions.current[dimension.id ?? ""]) && (
													<RequiredDimensionsValues data-testid="add-line-item-dimension-mix-max">
														{`Min: ${dimension.minValue}", Max: ${dimension.maxValue}"`}
													</RequiredDimensionsValues>
												)}
											</div>
										)
									);
								})}
							</DimensionContainer>
						)}
					</>
				)}

				{foundSearch?.allowModifications && (
					<AddModification
						handleClearFieldError={handleSetModificationFormErrors}
						onModificationChange={handleSetModificationFormData}
						selectedEditItem={selectedEditItem}
						errors={modificationFormErrors}
						formValues={modificationFormValues}
						modifications={modifications}
					/>
				)}

				<AddLineItemButtonWrapper isAddLineItem={isAddLineItem}>
					<div>
						<Button
							data-testId="add-line-item-cancel-button"
							variant="text"
							onClick={() => addItemVisibility()}
						>
							{AddLineCancelButton}
						</Button>
					</div>

					<div>
						<Button
							data-testId="add-line-item-save-button"
							variant="outlined"
							type="submit"
						>
							{AddLineSaveButton}
						</Button>
						<div>{AddLineEnterText}</div>
					</div>

					<div>
						<Button
							data-testId="add-line-item-add-another-button"
							variant="contained"
							onClick={handleAddAnother}
						>
							{AddLineAddAnotherItemButton}
						</Button>
						<div>{AddLineAddAnotherTextOne}</div>
					</div>
				</AddLineItemButtonWrapper>
			</AddLineItemContainer>
		</form>
	);
};

export default AddLineItem;
