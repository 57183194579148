import { type FC, useEffect, useMemo, useRef, useState } from "react";
import {
	AddNewAddressLink,
	EmailLabel,
	PhoneLabel,
	PhoneLabelOptional,
	ReplacementReviewAddress,
	ReplacementReviewNoShipAddress,
	ReplacementReviewShippingToHeader,
	ReplacementsCancelButtonText,
	ReplacementShipToFormDeliveryText,
	ReplacementShipToFormPickupText,
	ReplacementShipToFormUsingInfoText,
	ReplacementShipToOrderContactText,
	ReplacementShipToShippingMethodText,
	ReplacementsShipToFormShippingLabelNotes,
	ReplacementsShipToFormShippingLabelSubheader,
	RequiredFieldsText,
	SaveButtonText
} from "constants/text";
import {
	NewOrderRequestedDateHelperText,
	NewOrderRequestedDateLabel,
	NewOrderRequestedDateLaterDateButton,
	NewOrderRemoveButton,
	SelectAnAddress
} from "components/NewOrders/constants";
import { AddressFormFields } from "pages/Replacements/forms/AddressForm/schema";
import AddNewAddressModal from "pages/Replacements/components/AddNewAddressModal/AddNewAddressModal";
import AddressFormProvider from "pages/Replacements/forms/AddressForm/AddressFormProvider";
import { Button, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { AddAddress, ReplacementsShipToFormTextField } from "components/Replacements/replacementShipToForm.styles";
import { getFormFieldProps } from "utils/form";
import MaskedPhoneTextField from "components/Replacements/components/MaskedPhoneTextField/MaskedPhoneTextField";
import styles from "pages/replacements-page-styles.module.css";
import {
	DELIVERY_INSTRUCTIONS_MAX_LENGTH,
	END_BUSINESS_DAY_RANGE,
	schema,
	START_BUSINESS_DAY_RANGE,
	type FormSchema as ShipToFormFields
} from "components/Replacements/ShipToForm/schema";
import {
	schema as contextSchema,
	ShippingDetailsSchema
} from "components/NewOrders/NewOrderShippingDetailsForm/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	InfoText,
	ShipToAddressGridItem,
	ShipToContainer,
	ShipToLabel,
	SubtitleText,
	NewOrderRequestedDeliveryDate,
	WarningContainer
} from "components/ShipToForm/shipToForm.styles";
import ConditionalForm from "./ConditionalForm";
import AutoComplete from "components/Common/Autocomplete/Autocomplete";
import { format, addBusinessDays } from "date-fns";
import { LinkButton } from "../Common/Link";
import WarningAmber from "@mui/icons-material/WarningAmber";
import { models } from "types/api/viewModels.ts";

export interface DefaultFormValues {
	email?: string;
	phoneNumber?: string | null;
	deliveryInstructions?: string;
	address?: models["ShipToViewModel"];
}

interface ShipToFormProps {
	allowLabelComments: boolean;
	analyticsDataId: string;
	combinedAddresses: models["ShipToViewModel"][];
	customerPickupAllowed: boolean;
	defaultFormValues?: DefaultFormValues | null;
	handleAddNewAddressModalSubmit: (address: AddressFormFields) => void;
	handleOnCancel: () => void;
	handleOnSave: (fields: ShipToFormFields | AddressFormFields, formIsCustomerPickup?: boolean) => void;
	handleSetShippingInformation: (address: models["ShipToViewModel"] | undefined) => void;
	hasFormContext?: boolean;
	isCustomerPickupDefault?: boolean;
	showCancelSaveButtons?: boolean;
}

const ShipToForm: FC<ShipToFormProps> = ({
	allowLabelComments,
	analyticsDataId,
	combinedAddresses,
	customerPickupAllowed,
	defaultFormValues,
	handleAddNewAddressModalSubmit,
	handleOnCancel,
	handleOnSave,
	handleSetShippingInformation,
	hasFormContext,
	isCustomerPickupDefault,
	showCancelSaveButtons = false
}) => {
	/**
	 * When a customer can pickup an order, the following variables govern how address & phone number fields:
	 * --> Appear in the form
	 * --> Determine when they are required
	 *
	 * Variables:
	 *
	 * customerPickupAllowed:
	 * --> Prop comes from customer account
	 * --> indicates when a customer can pickup order instead of using a delivery method
	 *
	 * isCustomerPickupDefault:
	 * --> Prop determines if the customer pickup option should be selected by default over the delivery method option
	 *
	 * isCustomerPickup:
	 * --> State governing when the customer pickup option has been selected in the UI
	 */
	const [isCustomerPickup, setIsCustomerPickup] = useState<boolean>(isCustomerPickupDefault ?? false);
	const [isNewAddressModalOpen, setIsNewAddressModalOpen] = useState(false);
	const addressTextRef = useRef<HTMLSpanElement | null>(null);

	const defaultValues = useMemo(
		() =>
			defaultFormValues ?? {
				email: "",
				phoneNumber: "",
				deliveryInstructions: "",
				address: undefined
			},
		[defaultFormValues]
	);

	const contextFormProps = useFormContext<ShipToFormFields>();
	const localFormProps = useForm<ShipToFormFields>({
		mode: "onChange",
		resolver: yupResolver(schema),
		reValidateMode: "onChange",
		defaultValues
	});

	const { control, formState, handleSubmit, register, setValue, trigger, watch, clearErrors, getValues } = useMemo(
		() => (hasFormContext ? contextFormProps : localFormProps),
		[contextFormProps, hasFormContext, localFormProps]
	);

	const [isRequestedDeliveryDate, setIsRequestedDeliveryDate] = useState<boolean>(
		Boolean(getValues().requestedDeliveryDate)
	);

	const address = watch("address");

	const isPhoneRequired = isCustomerPickup ? customerPickupAllowed : address && !address.shipToId;

	useEffect(() => {
		if (formState.errors.address && !(formState.errors as ShippingDetailsSchema).poNumber) {
			addressTextRef?.current?.scrollIntoView({ block: "center", inline: "nearest" });
		}
	}, [formState.errors, formState.errors.address]);

	const formFieldData = { formState, register, schema: contextSchema };
	const shipToFormLabelComments = watch("deliveryInstructions") ?? "";

	useEffect(() => {
		setValue("isCustomerPickup", isCustomerPickup);
	}, [isCustomerPickup, setValue]);

	useEffect(() => {
		setValue("email", defaultFormValues?.email);
	}, [setValue, defaultFormValues?.email]);

	const handleShippingSelection = (event: any) => {
		clearErrors("phoneNumber");
		if (event.target.value === ReplacementShipToFormDeliveryText) {
			setIsCustomerPickup(false);
			setValue("isCustomerPickup", false);
		} else {
			setIsCustomerPickup(true);
			setValue("isCustomerPickup", true);
		}
	};

	const [resetAutocomplete, setResetAutocomplete] = useState(false);

	const getComparableString = (shipTo: models["ShipToViewModel"]): string => {
		return (
			shipTo.address?.line1?.trim() ??
			shipTo.address?.line2?.trim() ??
			shipTo.address?.line3?.trim() ??
			shipTo.address?.city?.trim() ??
			shipTo.address?.state?.trim() ??
			shipTo.address?.zip?.trim() ??
			""
		);
	};

	const sortAddresses = (addresses: models["ShipToViewModel"][]): models["ShipToViewModel"][] => {
		return addresses
			.filter((address, index, self) => {
				const hasOnlyUndefinedValues =
					address.address && Object.values(address.address).every((value) => value === undefined);

				if (!address.address || hasOnlyUndefinedValues) {
					return false;
				}
				// keeping this code until we confirm we want duplicates in the dropdown or not
				// const aComparable = getComparableString(address).toLowerCase();
				// return index === self.findIndex((other) => getComparableString(other).toLowerCase() === aComparable);
				return true;
			})
			.sort((a, b) => {
				const aComparable = getComparableString(a).toLowerCase();
				const bComparable = getComparableString(b).toLowerCase();

				if (aComparable < bComparable) return -1;
				if (aComparable > bComparable) return 1;
				return 0;
			});
	};

	const handleRequestedDeliveryDate = () => {
		setValue("requestedDeliveryDate", "");
		clearErrors("requestedDeliveryDate");
		setIsRequestedDeliveryDate((prevState) => !prevState);
		setDeliveryDateType("text");
	};

	const isConfirmation = window.location.toString().includes("new-order/shipping-details");

	const [deliveryDateType, setDeliveryDateType] = useState(getValues().requestedDeliveryDate ? "date" : "text");

	const getBusinessDayDate = (days: number) => {
		return format(addBusinessDays(new Date(), days), "yyyy-MM-dd");
	};

	const fiveMinFormatted = getBusinessDayDate(START_BUSINESS_DAY_RANGE);
	const ninetyFormatted = getBusinessDayDate(END_BUSINESS_DAY_RANGE);

	const getDefaultAddressValue = () => {
		if (defaultFormValues?.address) {
			return defaultFormValues.address;
		}
		const selectedAddress = combinedAddresses.find((address) => address.isSelected);
		return selectedAddress ?? undefined;
	};

	return (
		<ShipToContainer hasBorder={hasFormContext}>
			<ShipToLabel>{ReplacementReviewShippingToHeader}</ShipToLabel>
			<div>{RequiredFieldsText}</div>
			<ConditionalForm
				hasForm={showCancelSaveButtons}
				onSubmit={handleSubmit((formValues) => handleOnSave(formValues, isCustomerPickup))}
			>
				<Grid container>
					<Grid container>
						<Grid
							item
							xs={12}
						>
							<SubtitleText>{ReplacementShipToShippingMethodText}</SubtitleText>
						</Grid>
						{customerPickupAllowed ? (
							<Grid
								item
								xs={12}
							>
								<FormControl>
									<RadioGroup
										row
										onChange={handleShippingSelection}
									>
										<FormControlLabel
											value="Delivery"
											checked={!isCustomerPickup}
											control={<Radio size="small" />}
											label={<span className="body1">{ReplacementShipToFormDeliveryText}</span>}
											className="body1"
											data-testid="shipToForm-delivery-radioButton"
										/>
										<FormControlLabel
											value="Pickup"
											checked={isCustomerPickup}
											control={<Radio size="small" />}
											label={<span className="body1">{ReplacementShipToFormPickupText}</span>}
											className="body1"
											data-testid="shipToForm-pickup-radioButton"
										/>
									</RadioGroup>
								</FormControl>
							</Grid>
						) : (
							<Grid
								item
								xs={12}
							>
								<span className="body1">Delivery</span>
							</Grid>
						)}
					</Grid>

					{isConfirmation && (
						<NewOrderRequestedDeliveryDate>
							{!isRequestedDeliveryDate ? (
								<LinkButton
									data-testid="new-order-requested-delivery-date-button"
									onClick={handleRequestedDeliveryDate}
								>
									{NewOrderRequestedDateLaterDateButton}
								</LinkButton>
							) : (
								<>
									<TextField
										{...getFormFieldProps({
											name: "requestedDeliveryDate",
											...formFieldData,
											helperText: NewOrderRequestedDateHelperText
										})}
										data-testid="new-order-requested-delivery-date-field"
										label={NewOrderRequestedDateLabel}
										type={deliveryDateType}
										placeholder="MM/DD/YYYY"
										onFocus={() => setDeliveryDateType("date")}
										onBlur={() => trigger("requestedDeliveryDate")}
										InputLabelProps={{ shrink: true }}
										InputProps={{
											inputProps: {
												min: fiveMinFormatted,
												max: ninetyFormatted
											}
										}}
									/>

									<LinkButton
										data-testid="new-order-requested-delivery-date-remove-button"
										onClick={handleRequestedDeliveryDate}
									>
										{NewOrderRemoveButton}
									</LinkButton>
								</>
							)}
						</NewOrderRequestedDeliveryDate>
					)}

					<Grid
						item
						xs={12}
						sx={{ padding: "1rem 0" }}
					>
						<Divider />
					</Grid>
					{(!isCustomerPickup || (!customerPickupAllowed && isCustomerPickup)) && (
						<Grid container>
							<Grid
								item
								xs={12}
								sx={{ marginBottom: "8px" }}
							>
								<span
									ref={addressTextRef}
									className="subtitle2"
								>
									{ReplacementReviewAddress}
								</span>
							</Grid>
							{combinedAddresses.length > 0 ? (
								<ShipToAddressGridItem
									item
									xs={12}
								>
									<Controller
										name="address"
										control={control}
										defaultValue={getDefaultAddressValue()}
										render={({
											field: { onChange, value = getDefaultAddressValue() ?? null },
											fieldState: { error }
										}) => {
											return (
												<AutoComplete
													key={String(resetAutocomplete)}
													options={Array.from(new Set(sortAddresses(combinedAddresses)))}
													value={value}
													getOptionLabel={(option: models["ShipToViewModel"] | undefined) => {
														const formatAddress = (
															address: models["ShipToViewModel"]
														): string => {
															const { line1, line2, line3, city } = address.address ?? {};
															if (!(line1 || line2 || line3) && !city) return "";
															return `${address.address?.line1?.trim() ? address.address.line1 + " " : ""}${address.address?.line2?.trim() ? address.address.line2 + " " : ""}${address.address?.line3?.trim() ? address.address.line3 + " " : ""}${address.address?.city}, ${address.address?.state} ${address.address?.zip}`;
														};
														return option ? formatAddress(option) : "";
													}}
													onChange={(event, value) => {
														handleSetShippingInformation(value);
														onChange(value);
														trigger("phoneNumber");
													}}
													defaultValue={getDefaultAddressValue()}
													isError={!!error}
													errorText={error?.message}
													label={SelectAnAddress}
													dataTestId="replacementsShipToForm-address-select"
													required
													disableClearable
												/>
											);
										}}
									/>
								</ShipToAddressGridItem>
							) : (
								<>
									{!isCustomerPickup && (
										<WarningContainer data-testid="replacement-review-no-ship-to-address">
											<WarningAmber />
											<span>{ReplacementReviewNoShipAddress}</span>
										</WarningContainer>
									)}
								</>
							)}
							{/* role is for GA targeting */}
							<AddAddress
								onClick={() => setIsNewAddressModalOpen(true)}
								role="button"
								data-id={analyticsDataId}
							>
								{AddNewAddressLink}
							</AddAddress>
							<Grid
								item
								xs={12}
								sx={{ padding: "1rem 0" }}
							>
								<Divider />
							</Grid>
						</Grid>
					)}
					<Grid
						container
						spacing={2}
						justifyContent="space-between"
					>
						<Grid
							item
							xs={12}
						>
							<span className="subtitle2">{ReplacementShipToOrderContactText}</span>
							<br />
							<InfoText>{ReplacementShipToFormUsingInfoText}</InfoText>
						</Grid>
						<Grid
							xs={12}
							sm={6}
							item
						>
							<Controller
								control={control}
								render={({ field: { onChange, value = defaultFormValues?.phoneNumber ?? "" } }) => (
									<ReplacementsShipToFormTextField
										{...getFormFieldProps({
											name: "phoneNumber",
											schema: contextSchema,
											formState,
											required: isPhoneRequired
										})}
										InputLabelProps={{
											shrink: Boolean(watch("phoneNumber"))
										}}
										data-testid="shipToForm-phoneNumber-textField"
										label={isPhoneRequired ? PhoneLabel : PhoneLabelOptional}
										size="small"
										InputProps={{
											inputComponent: MaskedPhoneTextField as any
										}}
										onChange={onChange}
										value={value}
									/>
								)}
								name="phoneNumber"
							/>
						</Grid>
						<Grid
							xs={12}
							sm={6}
							item
						>
							<Controller
								control={control}
								render={({ field: { onChange, value = defaultFormValues?.email ?? "" } }) => (
									<ReplacementsShipToFormTextField
										{...getFormFieldProps({ name: "email", ...formFieldData })}
										label={EmailLabel}
										type="email"
										size="small"
										data-testid="shipToForm-email-textField"
										value={value}
										onChange={onChange}
									/>
								)}
								name="email"
							/>
						</Grid>
					</Grid>
					{!isCustomerPickup && allowLabelComments && (
						<Grid container>
							<Grid
								item
								xs={12}
								sx={{ padding: "1rem 0" }}
							>
								<Divider />
							</Grid>
							<Grid item>
								<span className="subtitle2">{ReplacementsShipToFormShippingLabelNotes}</span>
								<br />
								<InfoText>{ReplacementsShipToFormShippingLabelSubheader}</InfoText>
							</Grid>
							<Grid
								item
								xs={12}
								className={styles.deliveryInstructionsInputField}
							>
								<Controller
									control={control}
									render={({
										field: {
											onChange,
											value = defaultFormValues?.deliveryInstructions ?? shipToFormLabelComments
										}
									}) => (
										<ReplacementsShipToFormTextField
											{...getFormFieldProps({
												name: "deliveryInstructions",
												...formFieldData,
												characterLimit: {
													currentLength: shipToFormLabelComments.length,
													limit: DELIVERY_INSTRUCTIONS_MAX_LENGTH
												}
											})}
											onChange={onChange}
											label="Shipping Label Notes (optional)"
											value={value}
											multiline
											rows={4}
											data-testid="shipToForm-labelCommentsTextField"
										/>
									)}
									name="deliveryInstructions"
								/>
							</Grid>
						</Grid>
					)}
					{showCancelSaveButtons && (
						<Grid
							container
							spacing={2}
							justifyContent="space-between"
							className={styles.shipToFormButtons}
						>
							<Grid
								item
								xs={6}
							>
								<Button
									variant="outlined"
									fullWidth
									onClick={handleOnCancel}
									sx={{ marginLeft: 0 }}
									data-testid="shipToForm-cancel-button"
									data-id="shipToForm-cancel-button"
								>
									{ReplacementsCancelButtonText}
								</Button>
							</Grid>
							<Grid
								item
								xs={6}
							>
								<Button
									data-testid="shipToForm-save-button"
									data-id="shipToForm-save-button"
									variant="contained"
									fullWidth
									sx={{ marginLeft: 0 }}
									type="submit"
								>
									{SaveButtonText}
								</Button>
							</Grid>
						</Grid>
					)}
				</Grid>
				<AddressFormProvider>
					<AddNewAddressModal
						handleClose={() => setIsNewAddressModalOpen(false)}
						open={isNewAddressModalOpen}
						onSubmit={(address) => {
							handleAddNewAddressModalSubmit(address);
							setResetAutocomplete((prev) => !prev);
							setIsNewAddressModalOpen(false);
							const shippingAddress: models["ShipToViewModel"] = {
								shipToId: null,
								isActive: true,
								isSelected: true,
								address: {
									name: address.fullName,
									email: address.email,
									phoneNumber: address.contactPhoneNumber,
									line1: address.address,
									line2: address.address2,
									line3: null,
									city: address.city,
									state: address.state,
									zip: address.zip,
									county: address.county
								}
							};
							setValue("address", shippingAddress);
							setValue("phoneNumber", shippingAddress.address?.phoneNumber);
							setValue("email", shippingAddress.address?.email);
							clearErrors("address");
						}}
					/>
				</AddressFormProvider>
			</ConditionalForm>
		</ShipToContainer>
	);
};

export default ShipToForm;
