import { useCallback, useEffect, useState } from "react";
import { useGetModificationsQuery } from "features/api/newOrderApi.ts";
import { GetModificationsResponseData } from "types/api/products/getModifications.ts";
import { ModificationValues } from "components/NewOrders/AddModification/AddModification.tsx";

export type ModificationFormValues = Map<string, ModificationValues>;
export type ModificationFormErrors = Map<string, ModificationError>;

export interface ModificationError {
	selectedMod?: boolean;
	values?: boolean[];
}

function useAddModification(productId: string | null | undefined, productLineId: string | null | undefined) {
	const [modificationFormErrors, setModificationFormErrors] = useState<ModificationFormErrors>(new Map());
	const [modificationFormValues, setModificationFormValues] = useState<ModificationFormValues>(new Map());
	const [filteredModifications, setFilteredModifications] = useState<GetModificationsResponseData>();

	const { data: modifications } = useGetModificationsQuery(
		{
			productId: productId ?? "",
			productLineId: productLineId ?? ""
		},
		{ skip: !productId || !productLineId }
	);

	useEffect(() => {
		if (modifications) {
			const existingIds = Array.from(modificationFormValues.values()).map((value) => value?.id);

			setFilteredModifications((prev) => {
				return prev
					? modifications?.filter((mod) => {
							return mod?.id && !existingIds.includes(mod?.id);
						})
					: modifications;
			});
		}
	}, [modificationFormValues, modifications]);

	const handleSetModificationFormData = useCallback((values: ModificationFormValues) => {
		setModificationFormValues(values);
	}, []);

	const handleSetModificationFormErrors = useCallback((errors: ModificationFormErrors) => {
		setModificationFormErrors(errors);
	}, []);

	const getModificationFormErrors = useCallback((modificationFormValues: ModificationFormValues) => {
		let isValid = true;
		const errors = new Map<string, ModificationError>();
		modificationFormValues.entries().forEach(([key, value]) => {
			const isSelectedModInvalid = !value?.id;
			const valueRangeValidity = value?.values?.map((rangeValue) => !rangeValue?.value);

			if (isSelectedModInvalid || valueRangeValidity?.some((isInvalid) => isInvalid)) {
				isValid = false;
			}
			const error: ModificationError = {
				selectedMod: isSelectedModInvalid,
				values: valueRangeValidity
			};
			errors.set(key, error);
		});

		return {
			isValid,
			errors
		};
	}, []);

	return {
		getModificationFormErrors,
		handleSetModificationFormData,
		handleSetModificationFormErrors,
		modificationFormErrors,
		modificationFormValues,
		modifications: filteredModifications
	};
}

export default useAddModification;
