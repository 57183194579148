import { number, object, ObjectSchema, string } from "yup";

export interface FormSchema {
	productSelectQuantity: number;
	destinationConfigurationId?: string;
}

const editLineItemSchemaObject = {
	productSelectQuantity: number().required(),
	destinationConfigurationId: string()
} as const;

export const schema: ObjectSchema<FormSchema> = object(editLineItemSchemaObject);
