import AutoComplete from "components/Common/Autocomplete/Autocomplete.tsx";
import { calculateIntervals } from "components/NewOrders/utils/NewOrderUtils.tsx";
import { AddModificationIncrementError } from "components/NewOrders/AddModification/constants.ts";
import { AddModificationValues } from "components/NewOrders/AddModification/AddModificationStyles.ts";
import { FC, useMemo } from "react";
import { models } from "types/api/viewModels.ts";
import { Modification } from "components/NewOrders/AddModification/AddModification.tsx";

interface IncrementProps {
	handleIncrementChange(modification: models["ModificationViewModel"], value: number, rangeIndex: number): void;
	rangeIndex: number;
	rangeData: models["ModificationRangeViewModel"];
	modification: Modification;
	value: models["PendingValueViewModel"] | null | undefined;
	isError?: boolean;
	id?: string;
}
const Increment: FC<IncrementProps> = ({
	handleIncrementChange,
	rangeIndex,
	rangeData,
	modification,
	value,
	isError,
	id
}) => {
	const intervalOptions = useMemo(
		() => calculateIntervals(rangeData.minValue, rangeData.maxValue, rangeData.increment),
		[rangeData.increment, rangeData.maxValue, rangeData.minValue]
	);

	return (
		<div>
			<AutoComplete
				options={intervalOptions}
				getOptionLabel={(val) => String(val) + '"'}
				onChange={(_, value) => handleIncrementChange(modification, value, rangeIndex)}
				isError={isError}
				errorText={AddModificationIncrementError}
				label={value?.description ? `Select ${value.description}` : "Select a value"}
				dataTestId="add-line-item-modification-range-select"
				disableClearable
				defaultValue={value?.value}
				required
				id={id}
			/>

			{!isError && (
				<AddModificationValues data-testid="add-line-item-modification-range-value">
					{`Min: ${rangeData.minValue}", Max: ${rangeData.maxValue}"`}
				</AddModificationValues>
			)}
		</div>
	);
};

export default Increment;
