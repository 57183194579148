/* tslint:disable */

/**
 * Marketplace
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 *
 * @export
 * @enum {string}
 */
export enum ShippingType {
	UNKNOWN = "UNKNOWN",
	HOMEDELIVERY = "HOME_DELIVERY",
	CARRIERPICKUP = "CARRIER_PICKUP",
	CUSTOMERPICKUP = "CUSTOMER_PICKUP",
	INTRANSIT = "IN_TRANSIT",
	SHIP_TO_STORE = "SHIP_TO_STORE"
}
