import {
	BuildNewOrderLineItemsContainer,
	NewOrdersButtonWrapper,
	NewOrdersLayoutContainer,
	NewOrdersContentContainer
} from "components/NewOrders/NewOrders.styles";
import { BackText, ContinueText } from "constants/text";
import { DesignerIsNotHereText, NewOrderShippingDetailsLabel } from "components/NewOrders/constants";
import ShippingOrderDetails from "components/NewOrders/ShippingOrderDetails";
import { useFormContext } from "react-hook-form";
import { useGetDesignersByAccountIdQuery } from "features/api/designerApi.ts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import ShipToForm, { DefaultFormValues } from "components/ShipToForm/ShipToForm";
import { useState, useMemo, useEffect, RefObject, useCallback } from "react";
import { AddressFormFields } from "pages/Replacements/forms/AddressForm/schema";
import { newOrderActions } from "features/reducers/newOrder/newOrder.ts";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import NewOrdersSubheader from "components/NewOrders/NewOrdersSubheader/NewOrdersSubheader";
import { submittingNewOrderActions } from "features/reducers/newOrder/submittingNewOrder.ts";
import NewOrdersHeader from "components/NewOrders/NewOrdersHeader/NewOrdersHeader";
import { BillToShipToBody, useNewOrderShipToAddressesMutation } from "features/api/newOrderApi.ts";
import { AddOnsProps } from "../BuildOrderPage/BuildOrderPage";
import useInitOrderDetail from "hooks/useInitOrderDetail";
import { PostOrdersRequestBody } from "types/api/orders/postOrders";
import { models } from "types/api/viewModels.ts";
import { getConfigurationAccountInfo } from "components/NewOrders/utils/NewOrderUtils.tsx";
import { shippingDetailsActions } from "features/reducers/newOrder/shippingDetails";
import { ShipToViewModel } from "data/api/v1/model/ship-to-view-model";
import { displayDraftDesigner } from "utils/order.ts";
import { useGetAccountsByIdQuery } from "features/api/accountApi";
import { ShippingType } from "data/api/v1";

const NewOrderShippingDetailsPage = ({ isAddOn }: AddOnsProps) => {
	const shippingDetails = useSelector((state: RootState) => state.shippingDetails);
	const [contentOffset, setContentOffset] = useState(0);
	const calcHeightOfHeaderForContentTopOffset = (headerContainer: RefObject<HTMLDivElement>) => {
		setContentOffset(headerContainer.current?.clientHeight ?? 0);
	};
	const params = useParams();
	const { orderDetail } = useInitOrderDetail(params.orderId);
	const dispatch = useDispatch();
	const navigateTo = useNavigate();
	const addedAddresses = useSelector((state: RootState) => state.newOrder.newAddresses);
	const submittingNewOrder = useSelector((state: RootState) => state.submittingNewOrder.newOrder);
	const productLineAccountInfo = useSelector((state: RootState) => state.newOrder.productLineAccounts);
	const submittingOrderFromRedux = useSelector((state: RootState) => state.submittingNewOrder.newOrder);
	const draftOrder = useSelector((state: RootState) => state.submittingNewOrder.draftOrder);
	const defaultConfiguration = submittingNewOrder?.configurations?.[0] ?? draftOrder?.configurations?.[0];
	const accountNumberId = defaultConfiguration?.accountId ?? null;
	const defaultAccountInfo = useMemo(
		() => getConfigurationAccountInfo(defaultConfiguration ?? undefined, productLineAccountInfo),
		[defaultConfiguration, productLineAccountInfo]
	);
	const [draft, setDraft] = useState(draftOrder);

	const { data: designers } = useGetDesignersByAccountIdQuery(accountNumberId ?? "");
	const storedDraftOrder = useSelector((state: RootState) => state.submittingNewOrder.draftOrder);
	const isCustomerPickupAllowed = Boolean(defaultAccountInfo?.customerPickupAllowed);
	const allowLabelComments = Boolean(defaultAccountInfo?.allowLabelComments);
	const validatedOrderResponse = useSelector((state: RootState) => state.newOrder.validatedOrderResponse);

	const [newOrderShipToAddresses, { data: addressData, isLoading: addressesLoading }] =
		useNewOrderShipToAddressesMutation();

	const { data: accounts } = useGetAccountsByIdQuery(orderDetail?.orderId ?? "");
	const originalOrderShipTos = useMemo(
		() =>
			accounts
				?.filter((account) => account.isActive)
				.flatMap((account) => account.billTos?.flatMap((billTo) => billTo?.shipTos)),
		[accounts]
	);

	const [combinedAddresses, setCombinedAddresses] = useState<models["ShipToViewModel"][]>([]);

	useEffect(() => {
		if (!addressesLoading && addressData === undefined) {
			const shipToBodyCallback = (config: models["PendingOrderConfigurationViewModel"]): BillToShipToBody => ({
				accountId: String(config.accountId),
				billToId: String(config.billToId)
			});
			const allIds: BillToShipToBody[] =
				submittingNewOrder?.configurations?.map(shipToBodyCallback) ??
				draftOrder?.configurations?.map(shipToBodyCallback) ??
				[];

			if (allIds.length > 0) {
				newOrderShipToAddresses(allIds)
					.unwrap()
					.catch((err) => err);
			}
		}
	}, [
		addressData,
		addressesLoading,
		draftOrder?.configurations,
		newOrderShipToAddresses,
		submittingNewOrder?.configurations
	]);

	const shipToAddresses: models["ShipToViewModel"][] | undefined = useMemo(
		() =>
			(isAddOn ? originalOrderShipTos : addressData)?.map((address) => ({
				shipToId: address?.shipToId,
				isActive: address?.isActive ?? false,
				isSelected: address?.isSelected ?? false,
				address: {
					name: address?.address?.name ?? null,
					line1: address?.address?.line1,
					line2: address?.address?.line2,
					line3: address?.address?.line3,
					phoneNumber: address?.address?.phoneNumber,
					email: address?.address?.email,
					city: address?.address?.city,
					state: address?.address?.state,
					zip: address?.address?.zip,
					county: address?.address?.county
				}
			})),
		[addressData, originalOrderShipTos, isAddOn]
	);

	const [shippingInformation, setShippingInformation] = useState<models["ShipToViewModel"] | null>();

	useEffect(() => {
		if (combinedAddresses.length > 0) {
			const [firstShipToAddress] = combinedAddresses;
			setShippingInformation(firstShipToAddress);
		}
	}, [combinedAddresses]);

	const { handleSubmit, watch, getValues } = useFormContext();
	const jobName = watch("jobName");
	const poNumber = watch("poNumber");
	const designer = watch("designer");
	const customDesigner = watch("customDesigner");
	const address = watch("address");
	const email = watch("email");
	const isCustomerPickup = watch("isCustomerPickup");
	const phoneNumber = watch("phoneNumber");
	const requestedDeliveryDate = watch("requestedDeliveryDate");
	const deliveryInstructions = watch("deliveryInstructions");

	const saveShippingDetailsFormValuesToRedux = useCallback(() => {
		const shippingFormValues = getValues();
		dispatch(shippingDetailsActions.updateShippingDetails(shippingFormValues));
	}, [dispatch, getValues]);

	useEffect(() => {
		if (draft) {
			dispatch(submittingNewOrderActions.updateDraftOrder(draft));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const designerNumber =
			designer !== DesignerIsNotHereText && typeof designer !== "string"
				? designer?.designerNumber
				: customDesigner;
		const designerName =
			designer !== DesignerIsNotHereText && typeof designer !== "string"
				? `${designer?.firstName ? designer?.firstName + " " : ""}${designer?.lastName ?? ""}`
				: null;
		saveShippingDetailsFormValuesToRedux();

		setDraft((prev) => ({
			...prev,
			jobName,
			poNumber,
			designerName: designerName,
			designerNumber: designerNumber,
			isCustomerPickup: isCustomerPickup,
			shipToAddress: {
				name: !isCustomerPickup ? address?.address?.name : undefined,
				line1: !isCustomerPickup ? address?.address?.line1 : undefined,
				line2: !isCustomerPickup ? address?.address?.line2 : undefined,
				line3: !isCustomerPickup ? address?.address?.line3 : undefined,
				mailStop: !isCustomerPickup ? address?.address?.mailStop : undefined,
				phoneNumber: phoneNumber ?? undefined,
				email: email ?? undefined,
				city: !isCustomerPickup ? (address?.address?.city ?? null) : undefined,
				state: !isCustomerPickup ? (address?.address?.state ?? null) : undefined,
				zip: !isCustomerPickup ? (address?.address?.zip ?? null) : undefined,
				county: !isCustomerPickup ? (address?.address?.county ?? null) : undefined
			},
			shipToId: !isCustomerPickup ? address?.shipToId : undefined,
			requestedDeliveryDate: requestedDeliveryDate,
			labelComments: deliveryInstructions
		}));
	}, [
		jobName,
		poNumber,
		designer,
		address,
		email,
		isCustomerPickup,
		phoneNumber,
		requestedDeliveryDate,
		deliveryInstructions,
		customDesigner,
		saveShippingDetailsFormValuesToRedux
	]);

	const handleAddNewAddressModalSubmit = (address: AddressFormFields) => {
		const shippingAddress: models["ShipToViewModel"] = {
			shipToId: null,
			isActive: true,
			isSelected: true,
			address: {
				name: address.fullName,
				email: address.email,
				phoneNumber: address.contactPhoneNumber,
				line1: address.address,
				line2: address.address2,
				line3: null,
				city: address.city,
				state: address.state,
				zip: address.zip,
				county: address.county
			}
		};
		const updatedAddresses = combinedAddresses.map((addr) => ({
			...addr,
			isSelected: false
		}));

		const newCombinedAddresses = [...updatedAddresses, shippingAddress];

		setCombinedAddresses(newCombinedAddresses);
		dispatch(newOrderActions.addNewAddress(shippingAddress));
		setShippingInformation(shippingAddress);
	};

	const handleSetShippingInformation = (address: models["ShipToViewModel"] | undefined) => {
		setShippingInformation(address);
	};

	const handleOnCancel = () => {
		saveShippingDetailsFormValuesToRedux();
		dispatch(submittingNewOrderActions.clearDraftError());
		draft && dispatch(submittingNewOrderActions.updateDraftOrder(draft));
		navigateTo(`/${isAddOn ? "add-ons" : "new-order"}/view-price-estimates${isAddOn ? "/" + params.orderId : ""}`);
	};

	const handleOnSave = (values: any) => {
		saveShippingDetailsFormValuesToRedux();
		dispatch(submittingNewOrderActions.clearDraftError());
		const formattedDeliveryDate = `${values.requestedDeliveryDate}T00:00:00.000Z`;
		const isCustomerPickup = Boolean(values.isCustomerPickup);
		dispatch(submittingNewOrderActions.updateCustomDesignerNumber(values.customDesigner));
		const submittingOrder: PostOrdersRequestBody = {
			tandemOrderId: submittingOrderFromRedux?.tandemOrderId,
			jobName: values?.jobName?.trim(),
			poNumber: values?.poNumber?.trim(),
			orderType: submittingOrderFromRedux?.orderType,
			designerNumber:
				values.customDesigner ??
				(values.designer && values.designer !== DesignerIsNotHereText && typeof values.designer !== "string"
					? values.designer.designerNumber
					: values.designer.split("- ").pop()),
			labelComments: values.deliveryInstructions,
			requestedDeliveryDate: values.requestedDeliveryDate ? formattedDeliveryDate : null,
			originalOrderId: submittingOrderFromRedux?.originalOrderId,
			shipToId: !isCustomerPickup ? shippingInformation?.shipToId : undefined,
			isCustomerPickup: values.isCustomerPickup ?? false,
			shipToAddress: {
				name: !isCustomerPickup ? shippingInformation?.address?.name : undefined,
				line1: !isCustomerPickup ? shippingInformation?.address?.line1 : undefined,
				line2: !isCustomerPickup ? shippingInformation?.address?.line2 : undefined,
				line3: !isCustomerPickup ? shippingInformation?.address?.line3 : undefined,
				mailStop: !isCustomerPickup ? shippingInformation?.address?.mailStop : undefined,
				phoneNumber: values.phoneNumber ?? undefined,
				email: values.email ?? undefined,
				city: !isCustomerPickup ? shippingInformation?.address?.city : undefined,
				state: !isCustomerPickup ? shippingInformation?.address?.state : undefined,
				zip: !isCustomerPickup ? shippingInformation?.address?.zip : undefined,
				county: !isCustomerPickup ? shippingInformation?.address?.county : undefined
			},
			configurations: validatedOrderResponse?.configurations
		};
		const draftOrder: models["DraftOrderViewModel"] = {
			...submittingOrder,
			configurations: validatedOrderResponse?.configurations,
			draftOrderId: storedDraftOrder?.draftOrderId,
			draftName: storedDraftOrder?.draftName,
			designerNumber:
				values.customDesigner ??
				(values.designer && values.designer !== DesignerIsNotHereText && typeof values.designer !== "string"
					? values.designer.designerNumber
					: values.designer.split("- ").pop()),
			designerName: values.customDesigner
				? null
				: values.designer?.firstName || values.designer?.lastName
					? [values.designer?.firstName, values.designer?.lastName].filter(Boolean).join(" ")
					: values.designer.split(" -")[0],
			type: storedDraftOrder?.type
		};
		dispatch(submittingNewOrderActions.submittingNewOrder(submittingOrder));
		dispatch(
			submittingNewOrderActions.reviewOrderDisplay({
				...submittingOrder,
				configurations: validatedOrderResponse?.configurations
			})
		);
		dispatch(submittingNewOrderActions.updateDraftOrder(draftOrder));
		navigateTo(`/${isAddOn ? "add-ons" : "new-order"}/review-order${isAddOn ? "/" + params.orderId : ""}`);
	};

	const defaultAddress = useMemo<ShipToViewModel | undefined>(() => {
		return orderDetail?.shippingDetails?.shipToAddress
			? {
					shipToId: "ORIGINALSHIPTOADDRESS",
					isActive: true,
					isSelected: true,
					address: {
						name: orderDetail.shippingDetails.shipToAddress.name,
						line1: orderDetail.shippingDetails.shipToAddress.line1,
						line2: orderDetail.shippingDetails.shipToAddress.line2,
						line3: orderDetail.shippingDetails.shipToAddress.line3,
						mailStop: "",
						phoneNumber: orderDetail.shippingDetails.shipToAddress.phoneNumber,
						email: orderDetail.shippingDetails.shipToAddress.email,
						city: orderDetail.shippingDetails.shipToAddress.city,
						state: orderDetail.shippingDetails.shipToAddress.state,
						zip: orderDetail.shippingDetails.shipToAddress.zip,
						county: orderDetail.shippingDetails.shipToAddress.county
					}
				}
			: undefined;
	}, [orderDetail]);

	const [defaultFormValues, setDefaultFormValues] = useState<DefaultFormValues | undefined>();

	useEffect(() => {
		setDefaultFormValues((prev) => {
			if (!prev) {
				return orderDetail || shippingDetails
					? {
							phoneNumber:
								shippingDetails?.phoneNumber ||
								orderDetail?.shippingDetails?.shipToAddress?.phoneNumber,
							address: defaultAddress,
							email: shippingDetails?.email ?? orderDetail?.shippingDetails?.shipToAddress?.email,
							deliveryInstructions: orderDetail?.comments ?? undefined
						}
					: undefined;
			}
			return prev;
		});
	}, [defaultAddress, email, orderDetail, shippingDetails]);

	useEffect(() => {
		if (!addressesLoading && shipToAddresses) {
			setCombinedAddresses([...addedAddresses, ...shipToAddresses]);
		}
		if (defaultAddress) {
			setCombinedAddresses((prev) => {
				const foundDefaultAddress = prev.find((address) => address.shipToId === defaultAddress.shipToId);
				if (foundDefaultAddress) {
					return prev;
				} else {
					return [...prev, defaultAddress];
				}
			});
		}
	}, [addressesLoading, shipToAddresses, addedAddresses, defaultAddress]);

	return (
		<form
			onSubmit={handleSubmit(handleOnSave)}
			noValidate
		>
			<NewOrdersLayoutContainer>
				<NewOrdersHeader
					getContainerElement={calcHeightOfHeaderForContentTopOffset}
					draftOrder={draft}
					isAddOn={isAddOn}
				/>
				<NewOrdersContentContainer marginTopOffset={contentOffset}>
					<BuildNewOrderLineItemsContainer>
						<NewOrdersSubheader
							title={NewOrderShippingDetailsLabel}
							dataTestId="new-order-shipping-label"
							handleSearch={() => {}}
							draftOrder={draft}
							isAddOn={isAddOn}
						/>

						<ShippingOrderDetails
							designers={designers}
							defaultValues={{
								designer:
									(storedDraftOrder?.designerNumber && storedDraftOrder.designerNumber !== "--") ||
									(storedDraftOrder?.designerName && storedDraftOrder.designerName !== "--")
										? displayDraftDesigner(
												storedDraftOrder?.designerName,
												storedDraftOrder?.designerNumber
											)
										: orderDetail?.designer,
								poNumber: orderDetail?.poNumber,
								jobName: orderDetail?.jobName
							}}
							originalOrderDesigner={orderDetail?.designer}
						/>

						<ShipToForm
							allowLabelComments={allowLabelComments}
							analyticsDataId="new-order-shipping-details-new-address-link"
							combinedAddresses={combinedAddresses}
							defaultFormValues={defaultFormValues}
							customerPickupAllowed={isCustomerPickupAllowed ?? false}
							isCustomerPickupDefault={
								shippingDetails?.isCustomerPickup ??
								orderDetail?.shippingDetails?.shipVia === ShippingType.CUSTOMERPICKUP
							}
							handleAddNewAddressModalSubmit={handleAddNewAddressModalSubmit}
							handleOnCancel={handleOnCancel}
							handleSetShippingInformation={handleSetShippingInformation}
							handleOnSave={handleOnSave}
							showCancelSaveButtons={false}
							hasFormContext
						/>
					</BuildNewOrderLineItemsContainer>
					<NewOrdersButtonWrapper>
						<Button
							variant="text"
							data-testid="newOrderShippingDetails-backButton"
							onClick={handleOnCancel}
						>
							{BackText}
						</Button>
						<Button
							fullWidth
							variant="contained"
							data-testid="newOrderShippingDetails-continueButton"
							data-id="newOrderShippingDetails-continueButton"
							type="submit"
						>
							{ContinueText}
						</Button>
					</NewOrdersButtonWrapper>
				</NewOrdersContentContainer>
			</NewOrdersLayoutContainer>
		</form>
	);
};

export default NewOrderShippingDetailsPage;
